export default (theme) => ({

	root: {
		marginTop: '2.5em',
		width: 150,
		border: 'none',
		display: 'inline-block',
		padding: '14px 24px',
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		outline: 0,
		fontFamily: theme.typography.sansSerif.fontFamily,
	},
	primary: {
		backgroundColor: theme.palette.black,
		color: '#FFFFFF',
		'&:hover:not([disabled])': {
			'& p': {
				color: '#B40C24',
			},
			'& img': {
				filter: 'invert(100%)',
			},
		},
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	profilPictureText: {
		textAlign: 'center',
	},
	sectionFull: {
		background: '#FFF',
		height: '100vh',
		overflow: 'auto',
	},
	'@media(min-width: 1024px)': {
		profilPictureText: {
			textAlign: 'start',
		},
		sectionFull: {
			background: '#FFF',
			height: '100vh',
			overflow: 'auto',
		},
	},
});
