import ReactDOM from 'react-dom';

const ModalPortal = (props) => {
	const modalEl = document.getElementById('modal-root');

	return ReactDOM.createPortal(
		props.children,
		modalEl,
	);
};

export default ModalPortal;
