import React from 'react';

// Redux form
import { Field, reduxForm } from 'redux-form';
import { Grid, Col, Row } from 'react-flexbox-grid';
import withStyles from 'react-jss';
import Spotify from '../../../../assets/images/Spotify.svg';
import Youtube from '../../../../assets/images/Youtube.svg';
import Instagram from '../../../../assets/images/intagram.svg';
import Soundcloud from '../../../../assets/images/Souncloud.svg';
import InputField from '../Components/InputField';
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';
import ButtonSubmit from '../Components/Button';
import styles from './styles.js';
import { isValidURL, isrequired, isValidSocialLength } from '../../../containers/Wizard/validations/validate';

const SocialNetworks = (props) => {
	const { handleSubmit, classes, previousPage } = props;
	return (
		<section className={classes.sectionFull}>
			<Grid>
				<Row center="xs">
					<Col xs={12} sm={12} md={6} lg={6}>
						<Title
							FirstSentence="Tell Us More"
							SecondSentence="About your music"
						/>
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle Text="If you are not a soloist, enter the details of your label, band or group." />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10}>
						<form onSubmit={handleSubmit}>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="instagram"
									component={InputField}
									label="Instagram profile *"
									validate={[isValidURL, isrequired, isValidSocialLength]}
									image={Instagram}
									required
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="spotify"
									component={InputField}
									label="Spotify profile"
									validate={[isValidURL]}
									image={Spotify}
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="youtube"
									component={InputField}
									label="Youtube Channel"
									validate={[isValidURL]}
									image={Youtube}
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="soundcloud"
									component={InputField}
									label="Sound Cloud Music"
									validate={[isValidURL]}
									image={Soundcloud}
								/>
							</Col>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									paddingLeft: '9px',
									marginBottom: '1.75',
									marginTop: '3em',
								}}
							>
								<span
									style={{
										color: '#FFA938',
										fontFamily: 'Graphik',
										fontSize: '12px',
										lineHeight: '13px',
									}}
								>
									Complete at least two inputs to continue.
								</span>
							</div>
							<div className={classes.buttonContainer}>
								<div style={{ margin: '10px' }}>
									<button className={[classes.root, classes.primary].join(' ')} onClick={previousPage}>Back</button>
								</div>
								<div style={{ margin: '10px' }}>
									<ButtonSubmit>Next</ButtonSubmit>
								</div>
							</div>
						</form>
					</Col>
				</Row>
			</Grid>
		</section>
	);
};

const Social = withStyles(styles)(SocialNetworks);

export default reduxForm({
	form: 'wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Social);
