export default (theme) => ({
	btn: {
		backgroundColor: theme.palette.black,
		border: 'none',
		color: '#FFF',
		cursor: 'pointer',
		fontSize: '14px',
		marginTop: '35px',
		outline: 'none',
		padding: '14px 75px',
	},
	bgScreen: {
		backgroundColor: '#010101',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		top: '0',
		left: '0',
		position: 'fixed',
	},
	whiteContainer: {
		backgroundColor: theme.palette.white,
		width: '80%',
	},
	whiteBtn: {
		backgroundColor: '#fff',
		border: '1px solid #909090',
		color: '#909090',
		cursor: 'pointer',
		fontFamily: 'Graphik',
		fontSize: '14px',
		padding: '15px 50px',
	},
	containerModal: {
		paddingRigth: '8px',
		paddingLeft: '8px',
	},
	headerContainer: {
		borderBottom: '1px solid #D2D2D3',
		padding: '20px',
		height: '50px',
		marginBottom: '2em',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'baseline',
	},
	titleCont: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '16px',
	},
	inputContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '310px',
		marginBottom: '1em',
	},
	blackTitle: {
		fontFamily: theme.typography.header.solid,
		fontSize: '1.8em',
		lineHeight: '2em',
		height: '29px',
		width: '429px',
		color: theme.palette.black,
		textTransform: 'uppercase',
		marginRight: '20px',
	},
	redTitle: {
		fontFamily: theme.typography.header.outline,
		fontSize: '1.8em',
		lineHeight: '2em',
		height: '29px',
		width: '429px',
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
	},
	info: {
		height: 16,
		color: '#000000',
		fontFamily: theme.typography.sansSerif,
		fontSize: '14px',
		lineHeight: '16px',
		textAlign: 'center',
	},
	divBtn: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	'@media(min-width: 1024px)': {
		bgScreen: {
			backgroundColor: '#010101',
			height: '100vh',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		whiteContainer: {
			backgroundColor: theme.palette.white,
			height: '350px',
			width: '680px',
		},
		whiteBtn: {
			backgroundColor: '#fff',
			border: '1px solid #909090',
			color: '#909090',
			cursor: 'pointer',
			fontFamily: 'Graphik',
			fontSize: '14px',
			margin: '25px',
			padding: '15px 50px',
		},
		headerContainer: {
			borderBottom: '1px solid #D2D2D3',
			padding: '20px',
			height: '50px',
			marginBottom: '2em',
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			alignItems: 'baseline',
		},
		titleCont: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'baseline',
		},
		infoContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '16px',
		},
		inputContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '310px',
			marginBottom: '1em',
		},
		blackTitle: {
			fontFamily: theme.typography.header.solid,
			fontSize: '1.8em',
			lineHeight: '2em',
			height: '29px',
			width: '429px',
			color: theme.palette.black,
			textTransform: 'uppercase',
			marginRight: '20px',
		},
		redTitle: {
			fontFamily: theme.typography.header.outline,
			fontSize: '1.8em',
			lineHeight: '2em',
			height: '29px',
			width: '429px',
			color: theme.palette.primary[700],
			textTransform: 'uppercase',
		},
		info: {
			height: 16,
			width: '641px',
			color: '#000000',
			fontFamily: theme.typography.sansSerif,
			fontSize: '14px',
			lineHeight: '16px',
			textAlign: 'center',
		},
		divBtn: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
});
