import styled from 'styled-components';
import LandingImage from 'assets/images/uneek/LandingImage.jpg';
import { respondTo } from '../../../theme/_respondTo';

export const StyledLandingContainer = styled.div`
		width: 100%;
		height: 88vh;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
		background-image: url(${LandingImage});
		background-repeat: no-repeat;
		background-size: cover;
	${respondTo.xs`
		width: 100%;
		height: 88vh;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
		grid-auto-rows: 200px;
	`}
	${respondTo.sm`
		height: 80vh;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
		grid-auto-rows: 200px;
	`}
	${respondTo.md`
		height: 90vh;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
		grid-auto-rows:500px;
	`}
	${respondTo.lg`
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 20px;
		grid-auto-rows: minmax(500px,auto);
	`}
`;
