import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import onboardingState from './onBoardingReducer';

const rootReducer = combineReducers({
	form: reduxFormReducer,
	onboardingState,
});

export default rootReducer;
