import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import withStyles from 'react-jss';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';

import { required, maxLength50 } from 'Components/containers/Wizard/validations/validate.js';
import { fetchCountry, fetchCity } from '../../../../redux/actions';
import { getCountries, getCities } from '../../../../redux/selectors';
import styles from './styles.js';
// Form components
import Radio from '../Components/RadioButton';
import InputField from '../Components/InputField';
import AsyncCountrySelect from '../Components/AsyncSelect';
import CheckBox from '../Components/CheckBox';
import ButtonSubmit from '../Components/Button';
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';
import text from './text';

const PersonalInfo = (props) => {
	const {
		classes, handleSubmit, country,
	} = props;

	const [name, setName] = useState('');
	const [isSelectDisabled, setDisabled] = useState(true);

	useEffect(() => {
		if (country !== null) {
			setDisabled(!isSelectDisabled);
		}
	}, [country]);

	const handleChecked = (e) => {
		if (e.target.checked) {
			props.change('lastName', name);
		} else {
			props.change('lastName', '');
		}
	};

	const filterCountryOptions = async (inputValue) => await props.fetchCountry(inputValue);

	const filterCityOptions = async (inputValue) => await props.fetchCity(country, inputValue);

	return (
		<section className={classes.sectionFull}>
			<Grid>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Title FirstSentence="Tell Us" SecondSentence="About you" />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle
							Text="Enter your data and complete your application to be part of "
						>
							{text.brand}
						</Subtitle>
					</Col>
				</Row>
				<Row>
					<Col xs={10} sm={12} md={12} lg={9}>
						<p className={classes.requiredText}>
							<sup>*</sup>
							{' '}
							Required field
						</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Row center="xs">
							<Col xs={10}>
								<form onSubmit={handleSubmit}>
									<Field
										name="name"
										type="text"
										component={InputField}
										label="Your Name *"
										validate={[required, maxLength50]}
										onChange={(e) => setName(e.target.value)}
									/>
									<div>
										<div style={{ display: 'flex', justifyContent: 'space-around' }}>
											<Field
												label="Soloist"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="1"
											/>
											<Field
												label="Band or group"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="2"
												validate={[required]}
											/>
											<Field
												label="Label"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="3"
												validate={[required]}
											/>
										</div>
									</div>

									<Field
										name="lastName"
										type="text"
										component={InputField}
										label="Your project's name *"
										validate={[required]}
									/>
									<div style={{ display: 'flex' }}>
										<Field
											label="It's the same as my real name"
											name="same"
											component={CheckBox}
											type="checkbox"
											onChange={(e) => handleChecked(e)}
											value={name}
										/>
									</div>
									<div className={classes.regionInputs}>
										<Col xs={12} sm={12} md={6} lg={6}>
											<div
												style={{
													display: 'inline-block',
													flexDirection: 'row',
													justifyContent: 'space-around',
													width: '100%',
												}}
											>
												<Field
													label="Country"
													name="country"
													component={AsyncCountrySelect}
													validate={[required]}
													placeholder="Enter your country"
													filterFunction={filterCountryOptions}
												/>
											</div>
										</Col>
										<Col xs={12} sm={12} md={6} lg={6}>

											<div
												style={{
													display: 'inline-block',
													flexDirection: 'row',
													justifyContent: 'space-around',
													width: '100%',
												}}
											>
												<Field
													label="City"
													name="city"
													component={AsyncCountrySelect}
													validate={[required]}
													placeholder="Enter your city"
													filterFunction={filterCityOptions}
													disabled={!isSelectDisabled}
												/>
											</div>
										</Col>
									</div>

									<div className={classes.terms}>
										<Field
											label=""
											name="terms"
											component={CheckBox}
											type="checkbox"
											validate={[required]}
										/>
										<p className={classes.p}>
											I accept
											{' '}
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a href="#" className={classes.textRed}>Terms and Conditions</a>
											{' '}
											and
											{' '}
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a href="#" className={classes.textRed}>Privacy Policy</a>
											{' '}
											of UNEEK.
											{' '}
											<br />
											And I accept
											{' '}
											<a href="https://bquate.com/termsandconditions_en" target="_blank" className={classes.textRed} rel="noopener noreferrer">
												Terms and Conditions
											</a>
											{' '}
											and
											{' '}
											<a href="https://bquate.com/privacypolicy" target="_blank" className={classes.textRed} rel="noopener noreferrer">
												Privacy Policy
											</a>
											{' '}
											of Bquate.
										</p>
									</div>
									<div>
										<ButtonSubmit>Next</ButtonSubmit>
									</div>
								</form>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>

		</section>
	);
};

let PersonalInformation = withStyles(styles)(PersonalInfo);

const mapDispatchToProps = {
	fetchCountry,
	fetchCity,
};

const mapStateToProps = (state) => {
	const selector = formValueSelector('wizard');
	return {
		countries: getCountries(state),
		cities: getCities(state),
		country: selector(state, 'country'),
	};
};

PersonalInformation = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PersonalInformation);

export default reduxForm({
	form: 'wizard', //                 <------ same form name
	destroyOnUnmount: false, //        <------ preserve form data
	forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PersonalInformation);
