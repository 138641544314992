import { reset } from 'redux-form';
import {
	SAVE_USER_TOKEN, GET_COUNTRY, GET_CITY, SAVE_IMAGE,
	HANDLE_DIALOG,
	EMAIL_EXIST,
} from '../types';
import OnboardingService from '../../Service/Onboarding';
import { ApiConstants } from '../../constants/AppConstants';

export const saveUserData = (userData, cityName, countryName) => async (dispatch) => {
	const response = await OnboardingService.register(userData, cityName, countryName);
	const { data, success, code } = response;
	if (code.toString() === ApiConstants[3100]) {
		const payload = {
			token: data.token,
			successRegister: success,
			openImageDialog: success,
		};
		dispatch({ type: SAVE_USER_TOKEN, payload });
		dispatch(reset('wizard'));
	} else if (code.toString() === ApiConstants[3009]) {
		dispatch({ type: EMAIL_EXIST, emailExist: response.errors });
	}
};

export const fetchCountry = (data) => async (dispatch) => {
	const response = await OnboardingService.getCountry(data);
	dispatch({ type: GET_COUNTRY, countries: response.data });
	return response.data;
};

export const fetchCity = (id, currentValue) => async (dispatch) => {
	const response = await OnboardingService.getCity(id, currentValue);
	dispatch({ type: GET_CITY, cities: response.data });
	return response.data;
};

export const sendImage = (file, token) => async (dispatch) => {
	const response = await OnboardingService.saveImage(file, token);
	dispatch({ type: SAVE_IMAGE, imageSend: true });
	return response;
};

export const manageDialog = () => (dispatch) => {
	dispatch({ type: HANDLE_DIALOG, openImageDialog: false });
};
