import React from 'react';

import close from 'assets/images/close_secundary.svg';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import styles from './styles';

const ResetModal = ({
	classes, closeModal, icon, title1, title2, text, buttonText, redirect,
}) => (
	<div className={classes.bgScreen}>
		<div className={classes.whiteContainer}>
			<div className={classes.headerContainer}>
				<div className={classes.titleCont}>
					<div>
						<img
							style={{ height: '24px', width: '24px', marginRight: '20px' }}
							src={icon}
							alt="lock-confirm"
						/>
					</div>
					<div>
						<span className={classes.blackTitle}>{title1}</span>
						<span className={classes.redTitle}>{title2}</span>
					</div>
				</div>

				<div>
					<img
						style={{
							height: '24px',
							width: '24px',
							marginLeft: '30px',
							cursor: 'pointer',
						}}
						src={close}
						alt="close"
						onClick={() => closeModal()}
					/>
				</div>
			</div>
			<section className={classes.infoContainer}>
				<p style={{ textAlign: 'center', lineHeight: '1.5' }}>
					{text}
				</p>
				<Link to={redirect}>
					<button className={classes.btn}>{buttonText}</button>
				</Link>
			</section>
		</div>
	</div>
);

export default withStyles(styles)(ResetModal);
