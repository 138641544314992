import React from 'react';
import { connect } from 'react-redux';

import WizardForm from './Wizard/WizardForm';
import NavBar from '../presentational/Utils/Navbar';
import { saveUserData } from '../../redux/actions';
import { getCities, getCountries } from '../../redux/selectors';

const ApplyContainer = (props) => {
	const sendData = (values) => {
		props.saveUserData(values, props.cities[0].name, props.countries[0].name);
	};

	return (
		<>
			<NavBar />
			<WizardForm onSubmit={sendData} />
		</>
	);
};

const mapStateToProps = (state) => ({
	countries: getCountries(state),
	cities: getCities(state),
});

const mapDispatchToProps = {
	saveUserData,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ApplyContainer);
