import React, { useState } from 'react';
import withStyles from 'react-jss';
import { Link, useHistory } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './styles';
import Navbar from '../Utils/Navbar/index';
import AuthService from '../../../Service/Auth';
import config from '../../../config';
import apiPaths from '../../../helpers/apiPaths';

const Login = (props) => {
	const { classes } = props;
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = {
			user: {
				email,
				password,
			},
		};
		const response = await AuthService.loginUser(payload);
		if (response.status === 200) {
			const url = new URL(apiPaths.dashboard, config('dashboardLogin'));
			window.location.replace(`${url}`);
		} else {
			history.push('/');
		}
	};

	return (
		<>
			<Navbar />
			<section className={classes.sectionFull}>
				<Grid>
					<div className={classes.main}>
						<Row center="xs">
							<Col xs={10} sm={10} md={10} lg={10}>
								<h1 className={classes.title}>WELCOME!</h1>
							</Col>
						</Row>
						<Row>
							<Col xs={10} sm={10} md={10} lg={10}>
								<div className={classes.required}>
									<sup>*</sup>
									Required field
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Row center="xs">
									<Col xs={10}>
										<form autoComplete="off" onSubmit={handleSubmit}>
											<Row center="xs">
												<Col xs={12} sm={12} md={10} lg={10}>
													<label htmlFor="" className={classes.label}>
														Email
													</label>
													<input
														type="text"
														autoComplete="ño"
														className={classes.inputs}
														onChange={(e) => setEmail(e.target.value)}
														name="email"
													/>
													<br />
													<label htmlFor="" className={classes.label}>
														Password
													</label>
													<input
														type="password"
														autoComplete="off"
														className={classes.inputs}
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														name="password"
													/>
													<div className={classes.divBtn}>
														<button className={classes.btn} type="submit">
															LOG IN
														</button>
													</div>
												</Col>
											</Row>
										</form>
										<div />
										<div>
											<p className={classes.actions}>
												<>
													<span
														className={classes.red}
														onClick={() => history.push('/forgot-password')}
													>
														&nbsp;Forgot your password?
													</span>
												</>
												&nbsp; | Don't have account?
												<Link to="/apply" style={{ textDecoration: 'none' }}>
													<span className={classes.red}>&nbsp; Sign up</span>
												</Link>
											</p>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Grid>
			</section>
		</>
	);
};

export default withStyles(styles)(Login);
