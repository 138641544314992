import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Navbar from '../Utils/Navbar';
import styles from './styles.js';

import AuthService from '../../../Service/Auth/index';
import lockConfirm from '../../../assets/images/uneek/email_reset_password_confirm.png';
import ModalPortal from '../ModalPortal';
import LoginModal from '../LoginModal';
import text from './text';

const Reset = (props) => {
	const { classes } = props;

	const [open, isOpen] = useState(false);
	const [password, setPassword] = useState('');
	const [confirm, setConfirmation] = useState('');
	const [match, setError] = useState(false);
	const [error, setPasswordError] = useState('');
	const [email, setEmail] = useState('');
	const { passwordTexts } = text;
	// eslint-disable-next-line no-useless-escape
	const passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_!@#\$%\^&\*\/\.¿?])(?=.{8,})');

	useEffect(() => {
		if (passRegex.test(password)) {
			setPasswordError(false);
		} else {
			setPasswordError(true);
		}
	}, [password]);

	useEffect(() => {
		if (password !== confirm) {
			setError(true);
		} else {
			setError(false);
		}
	}, [confirm]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (password !== '' && password === confirm) {
			AuthService.resetPassword(password, confirm, email);
			isOpen(true);
		}
	};

	const handleModal = () => {
		isOpen(!open);
	};

	return (
		<div>

			<Navbar />
			<div className={classes.div}>
				<Grid>
					<Row center="xs">
						<Col xs={10} sm={10} md={10} lg={10}>
							<p className={classes.titleOne}>Reset</p>
							<p className={classes.titleTwo}>Password</p>
						</Col>
					</Row>
				</Grid>

				<div className={classes.formContainer}>
					<form className={classes.form} onSubmit={handleSubmit}>
						<label htmlFor="email">Email</label>
						<input
							id="email"
							type="email"
							placeholder="Enter youremail"
							autoComplete="off"
							className={classes.input}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label htmlFor="new-pass">New password</label>
						<input
							id="new-pass"
							type="password"
							placeholder="Enter your new password"
							autoComplete="off"
							className={classes.input}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<label htmlFor="confirm-pass">Confirm password</label>
						<input
							id="confirm-pass"
							type="password"
							placeholder="Confirm your new password"
							autoComplete="off"
							className={classes.input}
							onChange={(e) => setConfirmation(e.target.value)}
						/>
						{match ? <div style={{ color: '#f1b354', textAlign: 'center', margin: '10px' }}>{passwordTexts.notMatch}</div> : ''}
						{error ? <div style={{ color: '#f1b354', textAlign: 'center', margin: '10px' }}>{passwordTexts.notValid}</div> : ''}
						<button className={classes.btn}>
							NEXT
						</button>
					</form>
				</div>
			</div>
			{ open && (
				<ModalPortal>
					<LoginModal
						icon={lockConfirm}
						closeModal={handleModal}
						title1="Password"
						title2="updated"
						text="your password has been updated successfully!"
						redirect="/login"
						buttonText="LOGIN"
					/>
				</ModalPortal>
			)}
		</div>
	);
};

export default withStyles(styles)(Reset);
