/* eslint-disable no-dupe-keys */
export default (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		padding: '5px',
	},
	div: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '85vh',
		justifyContent: 'flex-start',
		background: '#ffffff',
	},
	btn: {
		backgroundColor: theme.palette.black,
		border: 'none',
		color: 'white',
		cursor: 'pointer',
		fontSize: '14px',
		outline: 'none',
		padding: '10px 55px',
		margin: '10px auto 0 auto',
	},
	titleOne: {
		fontFamily: 'Ultra System Sans',
		fontSize: '24px',
		margin: 0,
		marginTop: '45px',
		color: 'black',
	},
	titleTwo: {
		color: theme.palette.primary.main,
		fontFamily: 'Ultra System Sans Outline',
		fontSize: '24px',
		margin: 0,
	},
	label: {
		color: '#909090',
		fontFamily: 'Graphik',
		fontSize: '12px',
		margin: '25px 0px -12px 0px',
		width: '30%',
	},
	input: {
		width: 'auto',
		border: 'none',
		backgroundColor: '#FFF',
		height: '30px',
		outline: 'none',
		borderBottom: '1px solid #CCC',
		'&:focus': {
			borderBottom: '1px solid #000',
		},
		margin: '10px 0px',
		width: '30%',
		content: '',
		placeholder: 'Email',
		border: 'none',
		width: '100%',
		height: '25px',
		outline: 'none',
		'&:hover': {
			borderBottom: '1px solid black',
		},
	},
});
