/* eslint-disable no-unused-vars */
// You will add the new validations BELOW 👇

export const required = (value) => (value ? undefined : 'Required');

const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

export const maxLength50 = maxLength(50);

// eslint-disable-next-line no-useless-escape
const getRegexURLs = (name) => new RegExp(`${name}.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)[a-zA-Z0-9\.\-]{1,100}?$`);

export const isValidURL = (value, allValues, props, name) => {
	if (value && !getRegexURLs(name).test(value)) return `${name} url must be valid`;
	return undefined;
};

export const isValidSocialLength = (value, allValues, props, name) => {
	const networks = ['instagram', 'spotify', 'soundcloud', 'youtube'];
	const tmpSocial = [];
	// eslint-disable-next-line array-callback-return
	networks.map((key) => {
		if (key in allValues) {
			tmpSocial.push(key);
		}
	});
	if (tmpSocial.length < 2) return 'complete at least two social networks';
	return undefined;
};

export const isrequired = (value) => (!value ? 'Url is required' : '');

// email validation

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
	? 'Invalid email address' : undefined);

// Password Validations
export const matchPassword = (value, allValues) => (value !== allValues.password ? 'Passwords do not match' : undefined);

// eslint-disable-next-line no-useless-escape
const passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_!@#\$%\^&\*\/\.¿?])(?=.{8,})');
const erroPass = 'The password must contain a number, a capital letter, at least one special character and be a minimum of 8 characters';
export const isValidPassword = (value) => (value && passRegex.test(value) ? undefined : erroPass);
