import styled from 'styled-components';
import { respondTo } from '../../../../theme/_respondTo';

export const StyledInfoContainer = styled.div`
		padding: 0px;
		grid-column:2/3;
		align-items: center;
		animation: fadeIn ease 3s;
		-webkit-animation: fadeIn ease 3s;
		-moz-animation: fadeIn ease 3s;
		-o-animation: fadeIn ease 3s;
		-ms-animation: fadeIn ease 3s;
	${respondTo.xs`
		padding: 0px;
		grid-column:2/3;
    align-items: center;
	`}
	${respondTo.sm`
		grid-column:2;
		grid-row: 3;
		padding: 0px;
	`}
	${respondTo.md`
		margin-top: 30%;
		grid-column:2/3;
		grid-row: 2;
	`}
	${respondTo.lg`
		margin: 0;
		height: 30%;
		padding: 0px;
		grid-column:3/4;
		align-items: flex-start;
	`}
`;

export const StyledApplyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
	${respondTo.xs`
		width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
	`}
	${respondTo.sm`
		width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	`}
	${respondTo.md`
		width: 100%;
		display: flex;
    justify-content: center;
	`}
	${respondTo.lg`
		width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
	`}
`;

export const StyledSocialMedia = styled.div`
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: Graphik;
    justify-content: center;
    background-color: transparent;
	${respondTo.xs`
		width: 60%;
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: Graphik;
    justify-content: center;
    background-color: transparent;
	`}
	${respondTo.sm`
		width: 100%;
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: Graphik;
    justify-content: center;
    background-color: transparent;
	`}
	${respondTo.md`
		width: 100%;
    display: flex;
		margin-top: 1em;
    font-size: 12px;
    align-items: center;
    font-family: Graphik;
    justify-content: center;
    background-color: transparent;
	`}
	${respondTo.lg`
		width: 100%;
    bottom: 0;
    font-size: 12px;
    justify-content: flex-end;
    font-family: Graphik;
    background-color: transparent;
		margin: 0;
	`}
`;

export const StyledSocialIcons = styled.img`
	margin: 0px 10px;
	width: 20px;
	height: 20px;
	cursor: pointer;
	color: white;
`;

export const StyledButton = styled.button`
	border: 2px solid #FFFFFF;
	color: #FFFFFF;
	cursor: pointer;
	font-size: 11px;
	font-weight: 800;
	margin-top: 35px;
	outline: none;
	padding: 14px 75px;
	background-color: transparent;
	${respondTo.xxs`
    margin: 25px;
	`}
	${respondTo.lg`
		margin: 25px 0;
	`}
`;

export const StyledText = styled.h2`
	font-family: 'miller-banner';
	color: #869a60;
	font-weight: 800;
	font-size: 2.5em;
	text-align: center;
	text-transform: uppercase;
	transition: ease-in-out all 2.5s;
	${respondTo.xxs`
		margin: 0;
		font-size: 8vw;
		text-align: center;
	`}
	${respondTo.xs`
		width: 300px;
		font-family: 'miller-banner';
		color: #869a60;
		font-size: 1.8em;
	`}
	${respondTo.sm`
		width: 600px;
		font-family: 'miller-banner';
		color: #869a60;
		font-weight: 800;
		font-size: 8vw;
	`}
	${respondTo.md`
		width: 100%;
		font-family: 'miller-banner';
		text-align: center;
		color: #869a60;
		font-weight: 800;
		font-size: 8vw;
	`}
	${respondTo.lg`
		width: 100%;
		font-family: 'miller-banner';
		text-align: end;
		color: #869a60;
		font-weight: 800;
		font-size: 1.5vw;
	`}
`;
