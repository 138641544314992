export default (theme) => ({
	TextStyle: {
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginTop: '80px',
	},
	FirstText: {
		textTransform: 'uppercase',
		fontSize: '1.5em',
		color: theme.palette.black,
		fontFamily: theme.typography.header.solid,
	},
	SecondText: {
		textTransform: 'uppercase',
		fontSize: '1.5em',
		color: theme.palette.black,
		fontFamily: theme.typography.header.outline,
		marginLeft: '2%',
	},
	TextStyleLeft: {
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'left',
	},

});
