import React, { Component } from 'react';

import text from './text';
import * as Styled from './styled';

class SliderC extends Component {
	render() {
		return (
			<Styled.StyledSlider className="fade-in">
				<Styled.StyledTitle>
					{text.mainTitleWordOne}
					<Styled.StyledSpan>
						{text.mainTitleWordTwo}
					</Styled.StyledSpan>
				</Styled.StyledTitle>
			</Styled.StyledSlider>
		);
	}
}

export default SliderC;
