import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import eye from 'assets/images/eyeinactive_dark.svg';
import styles from './styles';

function PasswordInput({
	input, classes, placeholder, value, type, meta: { touched, error },
}) {
	const [typeInput, setType] = useState(type);
	const [fieldActive] = useState('');

	const handleShowPasswordClick = () => setType('text');

	const InputTextClasses = [
		classes.root,
		classes.placeholder,
		classes.line,
	].join(' ');

	useEffect(() => {
		const timer = setTimeout(() => setType('password'), 5000);

		return () => clearTimeout(timer);
	}, [typeInput]);

	return (
		<div className={classes.inputDivStyle}>
			<div>
				<label className={[classes.label, fieldActive, classes.inputLabel, classes.inputLabelStyle].join(' ')}>
					<input value={value} className={InputTextClasses} {...input} placeholder={placeholder} type={typeInput} />
					<div onClick={handleShowPasswordClick} className={classes.passwordEye}>
						<img style={{ marginTop: 4 }} src={eye} alt="" />
					</div>
				</label>

				{touched && error && <span className={classes.errorInputStyle}>{error}</span>}
			</div>
		</div>
	);
}

export default withStyles(styles)(PasswordInput);
