import styled from 'styled-components';
import { respondTo } from '../../../../theme/_respondTo';

export const StyledSlider = styled.div`
	width: 100%;
	grid-column:3/1;
	display: flex;
	flex-direction: column;
	justify-content: end;
	${respondTo.xxs`
		width: 100%;
		grid-column:4/1;
	`}
	${respondTo.xs`
		width: 100%;
		grid-column:3/1;
	`}
	${respondTo.sm`
		width: 100%;
		grid-column:2/3;
		grid-row: 2;
	`}
	${respondTo.md`
		width: 100%;
		grid-column:1/4;
		grid-row: 1;
	`}
	${respondTo.lg`
		grid-column:2/4;
	`}
`;

export const StyledTitle = styled.h1`
	font-family: Oswald;
	font-style: normal;
	font-weight: normal;
	font-size: 60px;
	line-height: 60px;
	text-align: right;
	letter-spacing: -0.03em;
	color: #FFFFFF;
	${respondTo.xxs`
		margin: 0;
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-size: 35px;
		-webkit-animation: fadeonresize1 0.6s ease-in-out;
	`}
	${respondTo.xs`
		width: 100%;
		font-size: 42px;
		grid-row: 2;
	`}
	${respondTo.sm`
	`}
	${respondTo.md`
		margin: 0;
		padding-top: 20%;
		color: white;
		text-align: center;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		line-height: 0.6;
	`}
	${respondTo.lg`
		color: white;
		text-align: right;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		line-height: 0.6;
	`}
`;

export const StyledSpan = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 100px;
	line-height: 100px;
	text-align: right;
	letter-spacing: -0.03em;
	color: #FFFFFF;
	${respondTo.xxs`
		font-size: 50px;
	`}
	${respondTo.xs`
		width: 100%;
	`}
	${respondTo.sm`
		width: 100%;
	`}
	${respondTo.md`
		width: 100%;
	`}
	${respondTo.lg`
		width: 100%;
	`}
`;
