export default (theme) => ({

	p: {
		color: '#474747',
		fontSize: '12px',
		fontFamily: 'Graphik',
		width: '90%',
		textAlign: 'left',
	},
	requiredText: {
		textAlign: 'right',
		fontFamily: 'Graphik',
		fontSize: '14px',
		color: '#909090',
		marginBottom: '-50px',
		display: 'none',
	},
	textRed: {
		color: theme.palette.black,
		fontSize: '12px',
		fontFamily: 'Graphik',
		textDecoration: 'underline',
	},
	inputs: {
		border: 'none',
		borderBottom: '1px solid #474747',
		outline: 'none',
		padding: '10px 0px',
		width: '100%',
		marginBottom: '20px',
		marginTop: '10px',
	},
	regionInputs: {
		display: 'flex',
		flexDirection: 'column',
	},
	terms: {
		display: 'flex',
		alignItems: 'center',
	},
	sectionFull: {
		background: '#FFF',
		height: '100vh',
		overflow: 'auto',
	},

	// Start media queries
	'@media(min-width: 1024px)': {
		sectionFull: {
			background: '#FFF',
			height: '100vh',
			overflow: 'auto',
		},
		p: {
			color: '#474747',
			fontSize: '12px',
			fontFamily: 'Graphik',
			width: '90%',
			textAlign: 'left',
		},
		requiredText: {
			textAlign: 'right',
			fontFamily: 'Graphik',
			fontSize: '14px',
			color: '#909090',
			marginBottom: '-50px',
		},
		textRed: {
			fontSize: '12px',
			fontFamily: 'Graphik',
			textDecoration: 'underline',
		},
		inputs: {
			border: 'none',
			borderBottom: '1px solid #474747',
			outline: 'none',
			padding: '10px 0px',
			width: '100%',
			marginBottom: '20px',
			marginTop: '10px',
		},
		regionInputs: {
			display: 'flex',
			flexDirection: 'row',
		},
		terms: {
			display: 'flex',
			alignItems: 'center',
		},
	},

});
