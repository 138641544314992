import styled from 'styled-components';
import { respondTo } from '../../../../theme/_respondTo';

export const StyledNavContainer = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 6.25em;
	background-color: black;
	${respondTo.xs`

	`}
	${respondTo.sm`
		padding: 0 42px;
	`}
	${respondTo.md`

	`}
	${respondTo.lg`
		padding: 0 42px;
	`}
`;

export const StyledLogoBquate = styled.img`
	width: 150px;
	display: none;
	${respondTo.xs`
		display: none;
	`}
	${respondTo.sm`
		display: block;
	`}
	${respondTo.md`
		display: block;
	`}
	${respondTo.lg`
		display: block;
	`}
`;

export const StyledButtonNav = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Button = styled.button`
	color: #000000;
	border: 2px solid #FFFFFF;
	cursor: pointer;
	height: 50%;
	padding: 10px 40px;
	outline: none;
	font-size: 11px;
	font-weight: 800;
	text-align: center;
	margin-right: 15px;
	background-color: #ffffff;
`;
