/* eslint-disable quote-props */
/* eslint-disable object-shorthand */
import config from '../../config';
import apiPaths from '../../helpers/apiPaths';

class OnboardingService {
	static async register(data, cityName, countryName) {
		const {
			country,
			city,
			name,
			musicalProfile,
			lastName,
			terms,
			spotify,
			soundcloud,
			youtube,
			instagram,
			email,
			password,
		} = data;
		const userObject = {
			acceptTerms: terms,
			bandName: lastName,
			cityId: city,
			countryId: country,
			cityName: cityName,
			countryName: countryName,
			email,
			name,
			password,
			social: {
				spotify: spotify,
				soundcloud: soundcloud,
				instagram: instagram,
				youtube: youtube,
			},
			type: Number(musicalProfile),
		};
		const url = new URL(apiPaths.register, config('apiUrl'));
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'app': `${process.env.REACT_APP_APP_NAME}`,
			},
			body: JSON.stringify(userObject),
		});
		return response.json();
	}

	static async getCountry(data) {
		const url = new URL(`${apiPaths.country}`, config('apiUrl'));
		url.searchParams.set('q', data);
		const response = await fetch(url, {
			method: 'GET',
			'Content-Type': 'application/json',
			headers: {
				// eslint-disable-next-line quote-props
				'app': `${process.env.REACT_APP_APP_NAME}`,
			},
		});
		return response.json();
	}

	static async getCity(id, currentValue) {
		const url = new URL(apiPaths.city, config('apiUrl'));
		url.searchParams.set('country', id);
		url.searchParams.set('q', currentValue);
		const response = await fetch(url, {
			method: 'GET',
			'Content-Type': 'application/json',
			headers: {
				// eslint-disable-next-line quote-props
				'app': `${process.env.REACT_APP_APP_NAME}`,
			},
		});

		return response.json();
	}

	static async saveImage(file, token) {
		const url = new URL(apiPaths.image, config('apiUrl'));
		url.searchParams.set('token', token);
		const data = new FormData();
		data.append('image', file);
		return (await fetch(url, {
			method: 'POST',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'app': `${process.env.REACT_APP_APP_NAME}`,
			},
			body: data,
		})).json();
	}
}

export default OnboardingService;
