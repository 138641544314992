import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from 'react-jss';

import lockReset from 'assets/images/email_reset_password.png';
import Navbar from 'Components/presentational/Utils/Navbar';
import AuthService from '../../../Service/Auth/index';
import styles from './styles.js';
import ModalPortal from '../ModalPortal';
import LoginModal from '../LoginModal';

const ForgotPassword = ({ classes }) => {
	const [inputValue, setValue] = useState('');
	const [open, setOpen] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		setOpen(true);
		AuthService.sendRequestToReset(inputValue);
		handleModal();
	};

	const handleModal = () => {
		setOpen(!open);
	};

	return (
		<div>
			<Navbar />
			<div className={classes.div}>
				<Grid>
					<Row center="xs">
						<Col xs={10} sm={10} md={10} lg={10}>
							<div>
								<p className={classes.titleOne}>Forgot Your</p>
								<p className={classes.titleTwo}>Password</p>
							</div>
						</Col>
					</Row>
				</Grid>
				<div style={{ width: '50%' }}>
					<form className={classes.form} onSubmit={handleSubmit}>
						<input
							type="email"
							placeholder="Enter your email"
							autoComplete="off"
							onChange={(e) => setValue(e.target.value)}
							className={classes.input}
							required
						/>
						<button className={classes.btn} type="submit">
							SEND EMAIL
						</button>
					</form>
					{open && (
						<ModalPortal>
							<LoginModal
								icon={lockReset}
								closeModal={handleModal}
								title1="Password reset"
								title2="request"
								text="We've received your password reset request and sent a message to your email, please check your inbox and follow the instructions. Thank you."
								redirect="/"
								buttonText="HOME"
							/>
						</ModalPortal>
					)}
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(ForgotPassword);
