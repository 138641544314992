import {
	GET_COUNTRY, GET_CITY, SAVE_USER_TOKEN, SAVE_IMAGE, HANDLE_DIALOG, EMAIL_EXIST,
} from '../types';

const initialState = {
	countries: [],
	isFormSent: false,
	cities: [],
	token: '',
	emailExist: '',
	imageSend: false,
	successRegister: false,
	openImageDialog: false,
};

const fetchCountries = (state, action) => ({ ...state, countries: [...action.countries] });

const fetchCities = (state, action) => ({ ...state, cities: [...action.cities] });

const saveUser = (state, action) => ({ ...state, ...action.payload });

const saveImage = (state, action) => ({ ...state, imageSend: action.imageSend });

const handleDialog = (state, action) => ({ ...state, openImageDialog: action.openImageDialog });

const emailExist = (state, action) => ({ ...state, emailExist: action.emailExist });

const actionHandlers = new Map([
	[GET_COUNTRY, fetchCountries],
	[GET_CITY, fetchCities],
	[SAVE_USER_TOKEN, saveUser],
	[SAVE_IMAGE, saveImage],
	[HANDLE_DIALOG, handleDialog],
	[EMAIL_EXIST, emailExist],
]);

export default (state = initialState, action = { type: null }) => (actionHandlers.has(action.type) ? actionHandlers.get(action.type)(state, action) : state);
