export default (theme) => ({

	// styles
	sectionFull: {
		backgroundColor: '#FFF',
		width: '100%',
	},
	container: {
		backgroundColor: '#ddd',
		display: 'flex',
		height: '90vh',
		justifyContent: 'space-between',
		width: '100%',
	},
	title: {
		fontFamily: 'Ultra System Sans',
		fontSize: '24px',
	},
	p: {
		fontFamily: 'Graphik',
		fontSize: '14px',
	},
	btn: {
		backgroundColor: theme.palette.black,
		border: 'none',
		color: '#FFF',
		cursor: 'pointer',
		fontFamily: 'Graphik',
		fontSize: '14px',
		margin: '25px',
		padding: '15px 50px',
	},
	whiteBtn: {
		backgroundColor: '#fff',
		border: '1px solid #909090',
		color: '#909090',
		cursor: 'pointer',
		fontFamily: 'Graphik',
		fontSize: '14px',
		margin: '25px',
		padding: '15px 50px',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		height: '90vh',
		backgroundColor: 'white',
		color: 'black',
	},
	inputs: {
		border: 'none',
		margin: '10px 0px',
		height: '24px',
		width: '100%',
		borderBottom: '1px solid #D2D2D3',
		outline: 'none',
	},
	red: {
		color: theme.palette.black,
		fontWeight: 500,
		textDecoration: 'blink',
		cursor: 'pointer',
	},
	actions: {
		fontFamily: 'Graphik',
		fontSize: '12px',
	},
	required: {
		color: '#909090',
		fontFamily: 'Graphik',
		fontSize: '12px',
		textAlign: 'right',
		width: '100%',
	},
	label: {
		color: '#909090',
		display: 'inline-block',
		fontFamily: 'Graphik',
		fontSize: '12px',
		width: '100%',
		textAlign: 'left',
	},
	divInputs: {
		width: '35%',
	},

	// modal styles

	bgScreen: {
		backgroundColor: '#010101',
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	whiteContainer: {
		backgroundColor: theme.palette.white,
		height: '400px',
		width: '680px',
		[theme.breakpoints.md]: {},
		[theme.breakpoints.sm]: {},
		[theme.breakpoints.lg]: {},
	},
	headerContainer: {
		borderBottom: '1px solid #D2D2D3',
		padding: '20px',
		height: '50px',
		marginBottom: '2em',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'baseline',
	},
	titleCont: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inputContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '310px',
		marginBottom: '1em',
	},
	blackTitle: {
		fontFamily: theme.typography.header.solid,
		fontSize: '1.8em',
		lineHeight: '2em',
		height: '29px',
		width: '429px',
		color: theme.palette.black,
		textTransform: 'uppercase',
		marginRight: '20px',
	},
	redTitle: {
		fontFamily: theme.typography.header.outline,
		fontSize: '1.8em',
		lineHeight: '2em',
		height: '29px',
		width: '429px',
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
	},
	info: {
		height: 16,
		width: '641px',
		color: '#000000',
		fontFamily: theme.typography.sansSerif,
		fontSize: '14px',
		lineHeight: '16px',
		textAlign: 'center',
	},
});
