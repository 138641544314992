import React, { useState } from 'react';
import { connect } from 'react-redux';

import ButtonSubmit from '../Button';
import File from '../InputFile';
import { getToken } from '../../../../../redux/selectors';
import { sendImage } from '../../../../../redux/actions';

const ProfileImageComponent = (props) => {
	const [image, setImage] = useState();
	const handleSubmit = async (event) => {
		event.preventDefault();
		const result = await props.saveImage(image, props.token);
		props.skip(result.success);
	};
	return (
		<>
			<form onSubmit={handleSubmit}>
				<File imageFile={setImage} />
				<ButtonSubmit>Save</ButtonSubmit>
			</form>
		</>
	);
};

const mapStateToProps = (state) => ({
	token: getToken(state),
});

const mapDispatchToProps = {
	saveImage: sendImage,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProfileImageComponent);
