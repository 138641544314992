import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/uneek/uneek-white.png';
import pbb from 'assets/images/pbb-white.svg';
import * as Styled from './styled';
import text from './text';

class Navbar extends Component {
	render() {
		const { login } = this.props;
		return (
			<>
				<Styled.StyledNavContainer>
					<Link to="/">
						<img width="150px" src={logo} alt="" />
					</Link>
					<Styled.StyledButtonNav>
						{ login
							? (
								<a href="https://uneekmusic.bquate.com/login">
									<Styled.Button>{text.textButton}</Styled.Button>
								</a>
							)
							: ''}
						<Styled.StyledLogoBquate src={pbb} />
					</Styled.StyledButtonNav>
				</Styled.StyledNavContainer>
			</>
		);
	}
}

export default Navbar;
