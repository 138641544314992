export const customStyles = {
	control: () => ({
		alignItems: 'center',
		backgroundColor: 'transparent',
		cursor: 'default',
		display: 'flex',
		flexWrap: 'wrap',
		MozBoxPack: 'justify',
		justifyContent: 'space-between',
		minHeight: 38,
		outline: 'currentcolor none 0px !important',
		position: 'relative',
		transition: 'all 100ms ease 0s',
		boxSizing: 'border-box',
		borderBottom: '1px solid #435278',
	}),
	option: () => ({
		color: 'black',
		padding: 10,
		fontFamility: 'Graphik',
		cursor: 'pointer',
	}),
};
