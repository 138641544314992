import React from 'react';
import withStyles from 'react-jss';

import Icon from 'assets/images/nota_production.svg';
import { usePreview } from '../../../../../hooks/index';
import styles from './styles';

const InputFile = ({ classes, imageFile }) => {
	const [url, handlePreview] = usePreview();

	const handleInputChange = (e) => {
		handlePreview(e);
		sendImage(e);
	};
	const sendImage = (e) => {
		imageFile(e.target.files[0]);
	};
	return (
		<div className={classes.root}>
			<div className={classes.imgContainer}>
				{url != null
					? <img style={{ width: '100%', height: '100%' }} src={url} alt="profile" /> : <img src={Icon} alt="profile" />}
			</div>
			<div className={classes.inputContainer}>
				<label className={classes.input}>
					<input
						accept="/*"
						id="profilePicture"
						name="file"
						type="file"
						style={{ display: 'none' }}
						onChange={handleInputChange}
					/>
					CHOOSE A FILE
				</label>
				<p className={classes.textHelper}>Size 1400 x 1400 px JPG or PNG format.</p>
			</div>
		</div>
	);
};

export default withStyles(styles)(InputFile);
