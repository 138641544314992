import React from 'react';
import AsyncSelect from 'react-select/async';
import { customStyles } from './styles';
import styles from './ContainerStyles.module.css';

const AsyncCountrySelect = ({
	filterFunction, name, input,
	placeholder, disabled,
	meta: { touched, error },
}) => (
	<div className={styles.ContainerStyles}>
		<AsyncSelect
			name={name}
			inputValue={input.value.id}
			value={input.value.id}
			loadOptions={filterFunction}
			getOptionLabel={(label) => label.name}
			getOptionValue={(value) => value.name}
			onBlur={(value) => input.onBlur(value.id)}
			onChange={(value) => input.onChange(value.id)}
			onFocus={(value) => input.onFocus(value.id)}
			placeholder={placeholder}
			styles={customStyles}
			isDisabled={disabled}
		/>
		{touched && error && <span className={styles.WarningText}>{error}</span>}
	</div>
);

export default AsyncCountrySelect;
