import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';

export default ({ text }) => (
	<>
		<DialogContentText style={{ textAlign: 'center' }}>
			{text}
		</DialogContentText>
	</>
);
