/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Instagram from 'assets/images/intagram.svg';
import Facebook from 'assets/images/Facebook.svg';
import * as Styled from './styled';
import text from './text';

class Info extends Component {
	render() {
		return (
			<Styled.StyledInfoContainer className="fade-in">
				<Styled.StyledApplyContainer>
					<Link to="/apply">
						<Styled.StyledButton>{text.apply.button}</Styled.StyledButton>
					</Link>
				</Styled.StyledApplyContainer>
				<Styled.StyledSocialMedia>
					<a href={text.socialNetworks.instagram} target="_blank" rel="noopener noreferrer">
						<Styled.StyledSocialIcons src={Instagram} alt="" />
					</a>
					<a href={text.socialNetworks.facebook} target="_blank" rel="noopener noreferrer">
						<Styled.StyledSocialIcons src={Facebook} alt="" />
					</a>
				</Styled.StyledSocialMedia>
			</Styled.StyledInfoContainer>
		);
	}
}

export default Info;
