import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Landing from './Components/presentational/Landing';
import Apply from './Components/containers/Apply';
import Login from './Components/presentational/Login';
import Reset from './Components/presentational/ResetPassword';
import ForgotPassword from './Components/presentational/ForgotPassword';

const Routes = () => (
	<Switch>
		<Route exact path="/" component={Landing} />
		<Route path="/apply" component={Apply} />
		<Route path="/login" component={Login} />
		<Route path="/forgot-password" component={ForgotPassword} />
		<Route path="/forgot" component={Reset} />
	</Switch>
);

export default Routes;
