import React from 'react';

import Navbar from '../Utils/Navbar/index.jsx';
import Info from '../Utils/Info/index.jsx';
import Slider from '../Utils/Slider/index.jsx';
import * as Styled from './styled';

const Landing = () => (
	<>
		<Navbar login />
		<Styled.StyledLandingContainer>
			<Slider />
			<Info />
		</Styled.StyledLandingContainer>
	</>
);

export default Landing;
