import config from '../../config';
import apiPaths from '../../helpers/apiPaths';

export default class AuthService {
	static async loginUser(data) {
		const url = new URL(apiPaths.login, config('dashboardLogin'));
		const { user } = data;
		const userData = new FormData();
		userData.append('email', user.email);
		userData.append('password', user.password);

		const response = await fetch(`${url}`, {
			method: 'POST',
			headers: {
				'Access-Control-Allow-Origin': '*',
			},
			body: userData,
		});
		return response;
	}

	static async sendRequestToReset(email) {
		const url = new URL(apiPaths.resetPasswordEmail, config('apiUrl'));
		const payload = {
			email,
		};
		const response = await fetch(`${url}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				App: `${process.env.REACT_APP_APP_NAME}`,
			},
			body: JSON.stringify(payload),
		});
		return response;
	}

	static async resetPassword(password, cfmpass, email) {
		const url = new URL(`${apiPaths.resetPassword}`, config('dashboardLogin'));
		const payload = {
			password,
			cfmpassword: cfmpass,
			hidemailid: email,
		};
		const response = await fetch(`${url}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				App: `${process.env.REACT_APP_APP_NAME}`,
			},
			body: JSON.stringify(payload),
		});
		return response;
	}
}
