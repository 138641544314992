export default (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputContainer: {
		display: 'flex',
		marginLeft: 10,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	input: {
		margin: '0 auto',
		textAlign: 'center',
		padding: '11px 0px',
		width: 160,
		backgroundColor: 'rgb(255, 255, 255)',
		border: `1px solid ${theme.palette.primary.main}`,
		cursor: 'pointer',
		display: 'inline-block',
		outline: 0,
		fontFamily: theme.typography.sansSerif,
		borderRadius: 0,
		color: theme.palette.primary.main,
	},
	textHelper: {
		marginLeft: '10px',
		color: 'rgb(144, 144, 144)',
		fontSize: 12,
	},
	errorInputStyle: {
		color: '#FFA938',
		fontSize: '0.75em',
	},
	'@media (min-width: 360px)': {
		imgContainer: {
			width: 145,
			border: '1px solid black',
			display: 'flex',
			overflow: 'hidden',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	/* Small (sm) */
	'@media (min-width: 640px)': {
		imgContainer: {
			width: 145,
			border: '1px solid black',
			display: 'flex',
			overflow: 'hidden',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	/* Medium (md) */
	'@media (min-width: 768px)': {
		imgContainer: {
			width: 145,
			height: 140,
			border: '1px solid black',
			display: 'flex',
			overflow: 'hidden',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	'@media(min-width: 1024px)': {
		root: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
		imgContainer: {
			width: 145,
			height: 140,
			border: '1px solid black',
			display: 'flex',
			overflow: 'hidden',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
});
