import React from 'react';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'react-jss';
import { Grid, Col, Row } from 'react-flexbox-grid';

import {
	required, matchPassword, email, isValidPassword,
} from 'Components/containers/Wizard/validations/validate';
import { connect } from 'react-redux';
import { emailExist } from '../../../../redux/selectors';
import styles from './styles';
import InputField from '../Components/InputField';
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';
import PassWordInput from '../Components/PasswordInput';
import ButtonSubmit from '../Components/Button';
import Dialog from '../Components/Dialog';
import text from './text';

const CreateAccount = (props) => {
	const {
		handleSubmit, pristine, submitting, classes,
		emailExistError, previousPage,
	} = props;

	return (
		<section className={classes.sectionFull}>
			<Grid>
				<Row center="xs">
					<Col xs={12} sm={12} md={4} lg={4}>
						<Title FirstSentence="Create" SecondSentence=" your Account" />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle
							Text="Enter your data to create your profile and get an answer once "
						>
							{text.brand}
						</Subtitle>
						<Subtitle
							Text=" reviews your project. "
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Row center="xs">
							<Col xs={10}>
								<form onSubmit={handleSubmit}>
									<Row>
										<Col xs={12} sm={12} md={12} lg={12}>
											<Field
												name="email"
												component={InputField}
												label="Email *"
												type="email"
												validate={[required, email]}
											/>
											{emailExistError !== '' ? <p style={{ color: '#f1b354' }}>{emailExistError}</p> : ''}
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} md={6} lg={6}>
											<Field
												type="password"
												name="password"
												component={PassWordInput}
												placeholder="password *"
												validate={[required, isValidPassword]}
											/>
										</Col>
										<Col xs={12} sm={12} md={6} lg={6}>
											<Field
												type="password"
												name="confirmPassword"
												component={PassWordInput}
												placeholder="confirm password *"
												validate={[required, isValidPassword, matchPassword]}
											/>
										</Col>
									</Row>
									<div className={classes.buttonContainer}>
										<div style={{ margin: '10px' }}>
											<button className={[classes.root, classes.primary].join(' ')} onClick={previousPage}>Back</button>
										</div>
										<div style={{ margin: '10px' }}>
											<ButtonSubmit disabled={pristine || submitting}>Submit</ButtonSubmit>
										</div>
									</div>

								</form>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
			<Dialog />
		</section>
	);
};

let CreateAcc = withStyles(styles)(CreateAccount);

const mapStateToProps = (state) => ({
	emailExistError: emailExist(state),
});

CreateAcc = connect(mapStateToProps, null)(CreateAcc);

export default reduxForm({
	form: 'wizard',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(CreateAcc);
