export default {
	login: '/login',
	register: 'onboarding/register',
	country: 'public/country',
	city: 'public/city',
	image: 'onboarding/register/image',
	resetPasswordEmail: 'onboarding/resetpassword',
	resetPassword: '/updatePassword',
	dashboard: 'dashboard',
};
