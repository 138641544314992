import React, { useState } from 'react';
import { connect } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import DynamicContent from '../DialogContents';
import ImageComponent from '../ImageComponent';
import { successRegister } from '../../../../../redux/selectors';
import { manageDialog } from '../../../../../redux/actions';
import style from './Dialog.module.css';

const DialogComponent = ({ open, handleDialog }) => {
	const [skip, setSkip] = useState(false);
	const history = useHistory();

	const handleDialogContent = () => {
		setSkip(true);
	};
	const returnToHome = () => {
		history.push('/');
		handleDialog();
	};
	return (
		<>
			<Dialog open={open} aria-labelledby="form-dialog-title">
				<DialogContent style={{ textAlign: 'center' }}>
					{skip
						? (
							<DynamicContent
								text="Thanks for applying! we are reviewing your application"
							/>
						)
						: (
							<DynamicContent
								text="Profile picture"
							/>
						)}
					{skip ? <DoneIcon className={style.Icon} /> : <ImageComponent skip={setSkip} onSend={handleDialog} />}
				</DialogContent>
				<DialogActions>
					{
						skip
							? (
								<Button onClick={returnToHome}>
									Return to home
								</Button>

							)
							: (
								<Button onClick={handleDialogContent}>
									Skip
								</Button>
							)
					}
				</DialogActions>
			</Dialog>
		</>
	);
};

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => {
	return {
		open: successRegister(state),
	};
};

const mapDispatchToProps = {
	handleDialog: manageDialog,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DialogComponent);
