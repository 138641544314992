export default (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		padding: 0,
		margin: 0,
		cursor: 'pointer',
		fontSize: '12px',
		height: 22,
		marginTop: '25px',
		marginBottom: '25px',
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		'user-select': 'none',
		'& input': {
			opacity: 0,
			cursor: 'pointer',
		},
		'& input:checked ~ $checkmark': { backgroundColor: 'red !important' },
		'& input:checked ~ $label': { color: theme.palette.grey[400] },
		'& input:checked ~ $checkmark:after': { display: 'block', backgroundColor: theme.palette.black },
		'& $checkmark:after': {
			top: 5,
			left: 5,
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: theme.palette.black,
		},
		...(((theme.overwrites(theme) || {}).radio || {}).root || {}),
	},
	checkmark: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: 18,
		width: 18,
		border: '1px solid ',
		borderRadius: '50%',
		'&:after': {
			content: '""',
			position: 'absolute',
			display: 'none',
		},
		...(((theme.overwrites(theme) || {}).radio || {}).checkmark || {}),
	},
	label: {
		color: theme.palette.grey[200],
		fontFamily: theme.typography.sansSerif.fontFamily,
		fontSize: '14px',
		marginLeft: 8,
		width: '100%',
	},
	errorInputStyle: {
		color: '#FFA938',
		fontSize: '0.75em',
	},
});
